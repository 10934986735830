import React, { useState, useEffect } from "react";
import Hero from "./components/Hero";
import AboutMe from "./components/AboutMe";
import GoToTheTop from "./components/Misc/GoToTheTop";
import Skills from "./components/Skills";
import Languages from "./components/Languages";
import Certificates from "./components/Certificates";
import Projects from "./components/Projects";
import Experiences from "./components/Experience";
import Footer from "./components/Footer";
import ContactMe from "./components/ContactMe";
import NavBar from "./components/NavBar";
import TabInfo from "./components/Misc/TabInfo";
import LoadingScreen from "./components/Misc/LoadingScreen";
import UnderConstructionWidget from "./components/Misc/UnderConstruction";
import getUserLanguage from "./utils/languageSelector";
import appTranslations from "./utils/appLanguageList";

function App() {
  const userLanguage = getUserLanguage();
  const { newTitle, originalTitle } =
    appTranslations[userLanguage] || appTranslations.en;

  // Call TabInfo with the titles
  TabInfo(newTitle, originalTitle);

  const [loading, setLoading] = useState(true);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    const handleImageLoad = () => {
      setIsFadingOut(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    const images = Array.from(document.images);
    const totalImages = images.length;
    let loadedImages = 0;

    if (totalImages === 0) {
      handleImageLoad();
    } else {
      images.forEach((image) => {
        if (image.complete) {
          loadedImages += 1;
          if (loadedImages === totalImages) {
            handleImageLoad();
          }
        } else {
          image.addEventListener("load", () => {
            loadedImages += 1;
            if (loadedImages === totalImages) {
              handleImageLoad();
            }
          });
          image.addEventListener("error", () => {
            loadedImages += 1;
            if (loadedImages === totalImages) {
              handleImageLoad();
            }
          });
        }
      });
    }
  }, []);

  return (
    <>
      {loading && <LoadingScreen isFadingOut={isFadingOut} />}
      <NavBar />
      <Hero />
      <AboutMe />
      <Skills />
      <Languages />
      <Certificates />
      <Projects />
      <Experiences />
      <ContactMe />
      <Footer />
      <GoToTheTop />
      <UnderConstructionWidget />
    </>
  );
}

export default App;
