import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import getUserLanguage from "../../utils/languageSelector"; // Import the language detection utility
import { skills, skillsTitle } from "../../utils/skillsList"; // Import updated skills

// SkillSection Component
const SkillSection = ({ title, tools, icon }) => (
  <motion.div
    className="text-center"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div className="flex items-center justify-center w-20 h-20 mb-3 mx-auto">
      <i
        className={icon}
        aria-hidden="true"
        style={{
          fontSize: "4rem",
          color: "#3B82F6",
        }}
      ></i>
    </div>
    <h6
      className="mb-3 font-semibold leading-5 border-b-2 pb-1"
      style={{
        fontFamily: "Poppins, sans-serif",
        color: "#111828",
        fontSize: "1.125rem",
        fontWeight: "600",
        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
      }}
    >
      {title}
    </h6>
    <div className="flex flex-wrap justify-center gap-1">
      {tools.map((tool, index) => (
        <a
          key={index}
          href={`https://img.shields.io/badge/${encodeURIComponent(
            tool.name
          )}-${tool.color}?style=flat&logo=${tool.logo}&logoColor=white`}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block"
        >
          <img
            src={`https://img.shields.io/badge/${encodeURIComponent(
              tool.name
            )}-${tool.color}?style=flat&logo=${tool.logo}&logoColor=white`}
            alt={tool.name}
            className="mb-1"
            style={{ maxWidth: "100px" }}
          />
        </a>
      ))}
    </div>
  </motion.div>
);

const Skills = () => {
  const language = getUserLanguage(); // Get user's language

  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: skillsRef, inView: skillsInView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const skillSectionVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const containerVariants = {
    hidden: {},
    visible: { transition: { staggerChildren: 0.2 } },
  };

  // Extract skill data based on the current language
  const skillTitles = skillsTitle[language];
  const { frontEnd, backEnd } = skillTitles;
  const {
    frontEnd: frontEndSkills,
    backEnd: backEndSkills,
    tools: toolsSkills,
  } = skills.technologies;

  return (
    <div
      id="skills"
      className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-16 lg:px-6 lg:py-14"
    >
      <div
        className="max-w-xl mb-6 md:mx-auto text-center lg:max-w-2xl md:mb-8"
        ref={titleRef}
      >
        <motion.h2
          className="max-w-lg mb-3 font-sans text-3xl lg:text-4xl font-bold leading-tight tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
          style={{
            fontFamily: "Poppins, sans-serif",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: titleInView ? 1 : 0 }}
          transition={{ duration: 0.6 }}
        >
          <span className="relative inline-block">
            <span className="relative" style={{ color: "#1E2A38" }}>
              {skillTitles.title}
            </span>
          </span>
        </motion.h2>
      </div>

      <motion.div
        ref={skillsRef}
        className="grid gap-6 row-gap-8 lg:grid-cols-3"
        initial="hidden"
        animate={skillsInView ? "visible" : "hidden"}
        variants={containerVariants}
      >
        <motion.div variants={skillSectionVariants}>
          <SkillSection
            title={frontEnd}
            tools={frontEndSkills}
            icon="text-blue-accent-700 text-4xl fab fa-html5"
          />
        </motion.div>
        <motion.div variants={skillSectionVariants}>
          <SkillSection
            title={skillTitles.tools}
            tools={toolsSkills}
            icon="text-blue-accent-700 text-4xl fa-solid fa-screwdriver-wrench"
          />
        </motion.div>
        <motion.div variants={skillSectionVariants}>
          <SkillSection
            title={backEnd}
            tools={backEndSkills}
            icon="text-blue-accent-700 text-4xl fa-solid fa-code"
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Skills;
