import techBadges from "./techBadges";

// Define getSkills function before using it
const getSkills = (names) => {
  return techBadges
    .filter((badge) => names.includes(badge.name))
    .map((badge) => ({
      name: badge.name,
      color: badge.color,
      logo: badge.logo,
    }));
};

const skillsTitle = {
  // Removed the extra colon
  en: {
    title: "My Skills",
    frontEnd: "FrontEnd",
    backEnd: "BackEnd",
    tools: "Tools",
  },
  pl: {
    title: "Moje Umiejętności",
    frontEnd: "FrontEnd",
    backEnd: "BackEnd",
    tools: "Narzędzia",
  },
};

const skills = {
  technologies: {
    frontEnd: getSkills([
      "HTML",
      "CSS",
      "TailwindCSS",
      "JavaScript",
      "React",
      "NextJs",
      "Qt",
      "APIs",
      "OAuth2",
      "Vercel",
      "Firebase",
    ]),
    backEnd: getSkills([
      "Python",
      "C++",
      "Django",
      "Flask",
      "Java",
      "Kotlin",
      "NodeJS",
      ".NET",
      "C#",
    ]),
    tools: getSkills([
      "VSCode",
      "Git",
      "Linux",
      "Docker",
      "JetBrains",
      "PowerShell",
      "Android Studio",
      "STM32CubeIDE",
      "Neovim",
      "Gradle",
      "Windows 11",
      "VirtualBox",
      "VMware",
      "MySQL",
      "PostgreSQL",
      "SQLite",
    ]),
  },
};

export { skills, skillsTitle };
