const techBadges = [
  // FrontEnd
  { name: "HTML", color: "E44D26", logo: "html5", category: "FrontEnd" },
  { name: "CSS", color: "264DE4", logo: "css3", category: "FrontEnd" },
  {
    name: "TailwindCSS",
    color: "06B6D4",
    logo: "tailwindcss",
    category: "FrontEnd",
  },
  {
    name: "JavaScript",
    color: "F7DF1E",
    logo: "javascript",
    category: "FrontEnd",
  },
  { name: "React", color: "61DAFB", logo: "react", category: "FrontEnd" },
  { name: "NextJs", color: "000000", logo: "next.js", category: "FrontEnd" },
  { name: "Dart", color: "0175C2", logo: "dart", category: "FrontEnd" },
  { name: "Flutter", color: "02569B", logo: "flutter", category: "FrontEnd" },
  { name: "Qt", color: "41CD52", logo: "qt", category: "FrontEnd" },
  { name: "APIs", color: "F7E03C", logo: "strapi", category: "FrontEnd" },
  { name: "OAuth2", color: "E54B4F", logo: "auth0", category: "FrontEnd" },
  { name: "Vercel", color: "000000", logo: "vercel", category: "FrontEnd" },

  // BackEnd
  { name: "Python", color: "4B8BBE", logo: "python", category: "BackEnd" },
  { name: "Django", color: "092E20", logo: "django", category: "BackEnd" },
  { name: "Flask", color: "000000", logo: "flask", category: "BackEnd" },
  { name: "Dash", color: "2496ED", logo: "dash", category: "BackEnd" },
  { name: "Java", color: "007396", logo: "openjdk", category: "BackEnd" },
  { name: "NodeJS", color: "8CC84B", logo: "node.js", category: "BackEnd" },
  { name: "Kotlin", color: "F18E33", logo: "kotlin", category: "BackEnd" },
  { name: "C#", color: "239120", logo: "csharp", category: "BackEnd" },
  { name: "R", color: "276DC3", logo: "r", category: "BackEnd" },
  { name: ".NET", color: "512BD4", logo: "dotnet", category: "BackEnd" },
  { name: "C++", color: "00599C", logo: "cplusplus", category: "BackEnd" },

  // Tools
  {
    name: "VSCode",
    color: "007ACC",
    logo: "visual%20studio%20code",
    category: "Tools",
  },
  { name: "Git", color: "F05032", logo: "git", category: "Tools" },
  { name: "GitHub", color: "181717", logo: "github", category: "Tools" },
  { name: "GitLab", color: "FCA121", logo: "gitlab", category: "Tools" },
  { name: "Bitbucket", color: "0052CC", logo: "bitbucket", category: "Tools" },
  { name: "JetBrains", color: "000000", logo: "jetbrains", category: "Tools" },
  { name: "WebStorm", color: "000000", logo: "webstorm", category: "Tools" },
  {
    name: "PowerShell",
    color: "003B57",
    logo: "powershell",
    category: "Tools",
  },
  { name: "Docker", color: "2496ED", logo: "docker", category: "Tools" },
  { name: "Linux", color: "FCC624", logo: "linux", category: "Tools" },
  {
    name: "IntelliJ",
    color: "000000",
    logo: "intellij-idea",
    category: "Tools",
  },
  {
    name: "Android Studio",
    color: "3DDC84",
    logo: "android-studio",
    category: "Tools",
  },
  { name: "PyCharm", color: "000000", logo: "pycharm", category: "Tools" },
  {
    name: "VirtualBox",
    color: "183A6D",
    logo: "virtualbox",
    category: "Tools",
  },
  { name: "VMware", color: "607078", logo: "vmware", category: "Tools" },
  {
    name: "Visual Studio",
    color: "5C2D91",
    logo: "visual-studio",
    category: "Tools",
  },
  {
    name: "STM32CubeIDE",
    color: "003D7A",
    logo: "stmicroelectronics",
    category: "Tools",
  },
  { name: "Neovim", color: "57A143", logo: "neovim", category: "Tools" },
  { name: "Gradle", color: "02303A", logo: "gradle", category: "Tools" },
  { name: "Windows 11", color: "0078D4", logo: "windows", category: "Tools" },

  // Additional Badges
  { name: "Sphinx", color: "F7C942", logo: "sphinx", category: "Tools" },
  { name: "JSON", color: "F7E03C", logo: "json", category: "Tools" },
  { name: "Firebase", color: "FFCA28", logo: "firebase", category: "Tools" },
  { name: "SMTP", color: "FF6F61", logo: "protonmail", category: "Tools" },
  {
    name: "Google Play",
    color: "34A853",
    logo: "google-play",
    category: "Tools",
  },
  { name: "AdMob", color: "F7C600", logo: "google-play", category: "Tools" },
  {
    name: "Google Play Billing",
    color: "FF5F4F",
    logo: "googlepay",
    category: "Tools",
  }, // Updated color
  {
    name: "GDScript",
    color: "8D3F5C",
    logo: "godot-engine",
    category: "Tools",
  }, // Updated color
  {
    name: "Godot Engine",
    color: "2496ED",
    logo: "godot-engine",
    category: "Tools",
  }, // Updated color
  { name: "MySQL", color: "4479A1", logo: "mysql", category: "Tools" },
  { name: "MongoDB", color: "47A248", logo: "mongodb", category: "Tools" },
  {
    name: "PostgreSQL",
    color: "4169E1",
    logo: "postgresql",
    category: "Tools",
  },
  { name: "SQLite", color: "003B57", logo: "sqlite", category: "Tools" },
];

export default techBadges;
