// utils/experienceList.js
import chmImage from "../../src/img/chm.webp"; // Adjust path accordingly
import dataPigeonsImage from "../../src/img/data_pigeons.webp"; // Adjust path accordingly

const experienceList = {
  title: {
    en: "Experience",
    pl: "Doświadczenie",
    // Add more languages here
  },
  experiences: {
    en: [
      {
        title: "Data Pigeons Student Group",
        date: "Since 06.12.2023 to Now",
        description: `I founded the Data Pigeons student group at the Bialystok University of Technology's Faculty of Computer Science. Alongside my primary collaborator, I organize our activities with valuable support from Dr. Magdalena Topczewska. Our group consists of more than 20 students who collaborate on data science and machine learning projects. We hold weekly meetings on Wednesdays via Microsoft Teams throughout the academic year and occasionally visit high schools to showcase our projects. Our work involves analyzing various datasets, including the Iris dataset, and visualizing results using Python, R, and Julia.`,
        image: dataPigeonsImage,
      },
      {
        title: "IT Department Internship",
        date: "July 1–31, 2022, and September 1–30, 2022",
        description: `Completed an internship in the IT department, where I gained hands-on experience in technical support and software development. I developed an application using Qt to manage duplicate records within a PostgreSQL database, enhancing data accuracy and system efficiency. My role also involved utilizing Python libraries like Pandas and NumPy for various development tasks. Additionally, I performed hardware troubleshooting, software installations, and ISO installations for computer systems.`,
        image: chmImage,
      },
    ],
    pl: [
      {
        title: "Grupa Studentów Data Pigeons",
        date: "Od 06.12.2023 do teraz",
        description: `Założyłem grupę studencką Data Pigeons na Wydziale Informatyki Politechniki Białostockiej. Wraz z moim głównym współpracownikiem zajmujemy się organizacją naszych działań, korzystając z cennego wsparcia dr Magdaleny Topczewskiej. Nasza grupa składa się z ponad 20 studentów, którzy współpracują przy projektach z zakresu data science i uczenia maszynowego. Organizujemy cotygodniowe spotkania w środy za pośrednictwem Microsoft Teams przez cały rok akademicki i od czasu do czasu odwiedzamy szkoły średnie, aby zaprezentować nasze projekty. Nasza praca obejmuje analizowanie różnych zbiorów danych, w tym zbioru Iris, oraz wizualizowanie wyników przy użyciu Pythona, R i Julii.`,
        image: dataPigeonsImage,
      },
      {
        title: "Staż w dziale IT",
        date: "1-31 lipca 2022 r. i 1-30 września 2022 r.",
        description: `Ukończyłem staż w dziale IT, gdzie zdobyłem praktyczne doświadczenie w wsparciu technicznym i rozwoju oprogramowania. Opracowałem aplikację przy użyciu Qt do zarządzania zduplikowanymi rekordami w bazie danych PostgreSQL, co poprawiło dokładność danych i efektywność systemu. W mojej roli korzystałem również z bibliotek Python, takich jak Pandas i NumPy, do różnych zadań programistycznych. Dodatkowo zajmowałem się diagnostyką sprzętu, instalacjami oprogramowania oraz instalacjami ISO dla systemów komputerowych.`,
        image: chmImage,
      },
    ],
    // Add more languages here
  },
};

export default experienceList;
